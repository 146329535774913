import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../../components/LayoutPreowned'
import YouTube from '../../components/homepage/YouTube'
import ProductGrid from '../../components/products/ProductGrid'

// markup // HARMONY WATCHES NEEDS HERO BANNER
const UsedVacheronConstantinHarmonyWatches = () => {
  const data = useStaticQuery(
    graphql`
      query queryUsedVacheronConstantinHarmonyWatches {
        products: allStrapiProduct(
          filter: { brand: { eq: "Vacheron Constantin" }, qty: { gt: 0 } }
          sort: { fields: stock_date, order: DESC }
          limit: 8
        ) {
          nodes {
            ...ProductCard
          }
        }
      }
    `
  )
  return (
    <Layout
      siteTitle={'Used Vacheron Constantin Harmony Watches for Sale'}
      canonical={'/fine-watches/other-watches-brands/vacheron-constantin'}
      description={
        'Grays & Sons are experts in buying and selling second hand Vacheron Constantin Harmony Watches with over 40 years of experience in the business'
      }
    >
      <div className="flex justify-center align-middle flex-col">
        <section>
          <div className="bg-gray-500 w-full flex justify-center">
            <a href="/fine-watches/other-watches-brands/vacheron-constantin/">
              <StaticImage
                src="../../images/preowned/Vacheron-overseas-watchbanner.jpg"
                alt="Pre-Owned Certified Used Vacheron Constantin Watches Header"
                aria-label="Used Vacheron Constantin Watches Header"
              />
            </a>
          </div>
        </section>

        <section className="p-5 lg:px-0 w-full flex justify-center">
          <div>
            <h1 className="text-3xl">
              Buy Sell Trade Consign Repair Restore Vacheron Constantin Harmony Watches
            </h1>
            <h2 className="p-5 lg:px-0 w-full flex justify-center">
              History of Vacheron Constantin Harmony watches
            </h2>
            <p>
              The year 2015 was a very special one for Vacheron Constantin. Not only were they
              celebrating 260 years of continuous innovation, but they were also introducing three
              fresh new collections to the shelves to commemorate. Seeing as it was such a
              significant Anniversary for the world’s oldest watch brand, these events were more
              than appropriate for Vacheron Constantin. One of the most esteemed collections VC used
              to celebrate the milestone is the Vacheron Constantin Harmony collection which
              references a vintage swiss style of Vacheron Constantin watches while still
              incorporating modern details and aesthetics into this line of timepieces. Appealing to
              many watch enthusiasts, the reference carries a sophisticated cushion-shaped case and
              a dial inspired by the 1928 VC original chronograph. The Harmony collection was
              released in a unit of five different calibers; each undergoing the ultimate tests of
              quality control Vacheron Constantin is worldly known for. Starting off with the
              diamond of the collection, the Vacheron Constantin Harmony Ultra-Thin Grande
              Complication Chronograph. This Harmony Grande Complication carries all the grace and
              efficiency that is the entire Harmony watch collection. Encased in a pristine platinum
              ultra-thin case, an automatic wind split–second mono-pusher chronograph movement
              comfortably sits providing nothing but accuracy. Also, we have the VC Harmony Dual
              Time for those who like the elegant feel of this watch model but prefer a more classic
              and less complicated dial. Perfect for jet-setters and pilots, the dual-time sub-dial
              displayed at 4 o’clock sits beautifully next to the AM/PM display at 8 o’clock. The
              Vacheron Constantin Harmony Tourbillon Chronograph embodies everything that is the
              innovative and creative visionaries at VC. At 12 o’clock of this Harmony model, a
              Tourbillon escapement is gracefully placed exposing the inner workings of this grand
              timepiece. The Vacheron Constantin Harmony Chronograph was directly inspired by the
              1928 chronograph yet given a twenty-first-century makeover. The pulsometer scale
              continues to give this watch a vintage feel by bordering the bezel from 2 to 9
              o’clock. This scale along with the two sub-dials stays true to the 1928 Chrono design
              but details such as blue steel hands and hour markers give this watch a futuristic
              look. Vacheron Constantin’s entire Harmony collection comes in a variety of different
              styles and aesthetics, there's one suitable for every consumer. Available in a variety
              of precious metals such as Platinum, 18k yellow/rose/white gold, and stainless steel;
              there is a Vacheron Constantin Harmony for the most casual to the most dress-up event!
              The release of this watch was the perfect way to celebrate 260 years of Vacheron
              Constantin-signed innovations.
            </p>

            <h2 className="text-3xl">
              Buying and Selling{' '}
              <a href="/fine-watches/other-watches-brands/vacheron-constantin/">
                Pre-Owned Vacheron Constantin Harmony Watches
              </a>
            </h2>
            <h2>
              Since its release, the Vacheron Constantin Harmony has been a huge success. These
              elegant timepieces draw the attention of any watch enthusiast that comes near them.
              The Vacheron Constantin Harmony collection is one of Vacheron Constantin’s versatile
              pieces appropriate for almost any setting. The umbrella of Vacheron Constantin Harmony
              watches holds a variety of designs that fall under the following references:
            </h2>

            <h2 className="text justify mx-auto text-2xl">
              Popular Vacheron Constantin Harmony watches and or references:
            </h2>

            <li>
              {' '}
              &ensp;
              <a href="/fine-watches/other-watches-brands/vacheron-constantin/">
                Pre-Owned Vacheron Constantin Harmony Chronograph References: 5000S/000R-B139,
                5005S/000R-B053, 5300s/000r-b055,5300S/000R-B124
              </a>{' '}
            </li>

            <li>
              {' '}
              &ensp;
              <a href="/fine-watches/other-watches-brands/vacheron-constantin/">
                Pre-Owned Vacheron Constantin Harmony Dual Time References: 7800S/000R-B140,
                7805S/000R-B140, 7810S/000G-B142
              </a>{' '}
            </li>

            <li>
              {' '}
              &ensp;
              <a href="/fine-watches/other-watches-brands/vacheron-constantin/">
                Pre-Owned Vacheron Constantin Harmony 4000s References:
                4000S/000R-B123,47450/000W-9511,7900V/000R-B336
              </a>{' '}
            </li>

            <li>
              {' '}
              &ensp;
              <a href="/fine-watches/other-watches-brands/vacheron-constantin/">
                Pre-Owned Vacheron Constantin Harmony Tourbillon References: 5100S/000R-B125,
                5100S/000P-B056
              </a>{' '}
            </li>

            <h2 className="text-2xl mx-auto">
              Gray &amp; Sons is the Best Source for Buying and Selling{' '}
              <a href="/fine-watches/other-watches-brands/vacheron-constantin/">
                Pre-Owned Vacheron Constantin Harmony Watches{' '}
              </a>{' '}
            </h2>

            <p>
              Since 1980, Gray and Sons Jewelers has been catering to clients all around the country
              with all their luxury needs. All of the services we provide are done in our very own
              store, here, in the heart of Miami, Florida. We are the top watch store in all of
              South Florida specializing in buying pre-owned Vacheron Constantin Harmony watches,
              Selling used Vacheron Constantin Harmony watches, repairing second-hand Vacheron
              Constantin Harmony watches, and trading genuine Vacheron Constantin Harmony
              timepieces. Gray and Sons Jewelers is the #1 all-stop shop for all Vacheron Constantin
              Harmony watch needs. Our store is located in South Florida in the city of Surfside
              across the street from the famous Bal Harbour Shops. Aside from buying Vacheron
              Constantin Harmony Watches and selling genuine pre-owned Vacheron Constantin Harmony
              watches, Gray and Sons is also a certified repair center for all Swiss-made
              wristwatches. We have six master-trained watchmakers in-house with over 150 years of
              combined experience to repair Vacheron Constantin Harmony watches, including the
              reparation of a Vacheron Constantin Harmony. We take in repairs for swiss-made watches
              daily! Those looking to “get cash for my used Vacheron Constantin Harmony watch” or
              “sell my pre-owned Vacheron Constantin Harmony watch today” should visit
              <a href="/"> www.grayandsons.com </a> or{' '}
              <a href="https://sellusyourjewelry.com/"> www.sellusyourjewelry.com </a>
              to request a free watch quote to sell or trade-in for one of ours! We have a
              brick-and-mortar store located in the heart of Miami, Fl near cities like Sunny Isles,
              Haulover, Aventura, Hollywood, Fort Lauderdale, Miami Beach, Coconut Grove, and Coral
              Gables, Key Biscayne, and many more. On our website, we have LIVE representatives
              Viktoria and <a href="/chat-with-rich">Rich</a>, who are specialists in buying and
              selling Vacheron Constantin Harmony watches. Our LIVE CHAT feature allows clients all
              around the nation to make all inquiries about Vacheron Constantin Harmony watches and
              possibly work out a deal in real-time. Gray and Sons Jewelers is the best and most
              reliable place to buy used pre-owned Vacheron Constantin Harmony watches. Our
              master-trained watchmakers assure every Vacheron Constantin Harmony watch in our
              inventory is certified pre-owned and serviced ready for wear with a 1-year warranty
              covering the movement.
            </p>
          </div>
        </section>
      </div>
      <div className="w-full flex mx-auto justify-center pb-24 pt-24">
        <a href="/fine-watches/other-watches-brands/vacheron-constantin/">
          <button>Shop Vacheron Constantin Watches</button>{' '}
        </a>
      </div>

      <section>
        <h2 className="text-center pb-48 ">IN-STOCK Vacheron Constantin Watches:</h2>

        <iframe
          className="content-center"
          src="https://www.google.com/maps/embed?pb=!1m19!1m8!1m3!1d7178.937737903439!2d-80.1235056!3d25.8869528!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x88d9b2992b408533%3A0x8d702d6011cda310!2sGray%20and%20Sons%20Jewelers%7C%20Luxury%20Estate%20Watches%20%26%20Jewelry%209595%20Harding%20Ave%20Surfside%2C%20FL%2033154!3m2!1d25.8869528!2d-80.1235056!5e0!3m2!1sen!2sus!4v1655907861510!5m2!1sen!2sus"
          width="1380"
          height="300"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
        <br />

        <ProductGrid products={data.products.nodes} />
      </section>

      <section></section>

      <section className="py-20">
        <h2 className="text-center py-10">VISIT GRAY &amp; SONS ON YOUTUBE</h2>
        <div className="w-full flex justify-center">
          <YouTube
            className={'w-full lg:w-3/4 xl:w-1/2 py-10 aspect-video'}
            title={'You Tube Video Title '}
            src={'https://www.youtube.com/embed/F_VZZhwYmGY'}
          />
        </div>
      </section>
    </Layout>
  )
}

export default UsedVacheronConstantinHarmonyWatches
